<!--
 * @Author: 李洋
 * @Date: 2021-07-02 15:20:00
 * @LastEditors: 李洋
 * @LastEditTime: 2021-07-02 17:29:08
 * @Description: 部门损益
-->
<template>
  <div class="custom-container">
    <div class="table-container">
      <div class="title">奶粉营销事业部2021年6月损益分析报表</div>
      <vxe-table border align="center" :data="tableData">
        <vxe-table-column field="a" title align="left"></vxe-table-column>
        <vxe-table-colgroup title="总计">
          <vxe-table-column field="b" title="预算"></vxe-table-column>
          <vxe-table-column field="c" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="直营">
          <vxe-table-column field="d" title="预算"></vxe-table-column>
          <vxe-table-column field="e" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="进销商">
          <vxe-table-column field="f" title="预算"></vxe-table-column>
          <vxe-table-column field="g" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="电商">
          <vxe-table-column field="h" title="预算"></vxe-table-column>
          <vxe-table-column field="i" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
      </vxe-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          a: '收入', b: '10000000.00', c: '12000000.00', d: '3000000.00', e: '3360000.00', f: '6500000.00', g: '8160000.00', h: '500000.00', i: '480000.00',
        },
        {
          a: '成本', b: '6000000.00', c: '7563520.00', d: '1800000.00', e: '2117785.60', f: '3900000.00', g: '5143193.60', h: '300000.00', i: '302540.80',
        },
        {
          a: '毛利额', b: '4000000.00', c: '4436480.00', d: '1200000.00', e: '1242214.40', f: '2600000.00', g: '3106806.40', h: '200000.00', i: '177459.20',
        },
        {
          a: '毛利率', b: '40.00', c: '36.97', d: '40.00', e: '36.97', f: '40.00', g: '36.97', h: '40.00', i: '36.97',
        },
        {
          a: '营销费用', b: '2000000.00', c: '1674953.00', d: '600000.00', e: '552902.84', f: '1300000.00', g: '1342764.04', h: '100000.00', i: '78989.12',
        },
        {
          a: '营销费用率', b: '20.00', c: '16.46', d: '20.00', e: '16.46', f: '20.00', g: '16.46', h: '20.00', i: '16.46',
        },
        {
          a: '其中：广告', b: '200000.00', c: '220000.00', d: '60000.00', e: '61600.00', f: '130000.00', g: '149600.00', h: '10000.00', i: '8800.00',
        },
        {
          a: '宣传费', b: '85000.00', c: '93500.00', d: '25500.00', e: '3360000.00', f: '6500000.00', g: '8160000.00', h: '500000.00', i: '3740.00',
        },
        {
          a: '促销', b: '670000.00', c: '737000.00', d: '201000.00', e: '3360000.00', f: '6500000.00', g: '8160000.00', h: '500000.00', i: '29480.00',
        },
        {
          a: '返利', b: '90000.00', c: '99000.00', d: '27000.00', e: '27000.00', f: '58500.00', g: '67320.00', h: '4500.00', i: '3960.00',
        },
        {
          a: '陈列', b: '60000.00', c: '45000.00', d: '18000.00', e: '12600.00', f: '39000.00', g: '30600.00', h: '3000.00', i: '1800.00',
        },
        {
          a: '运费', b: '40000.00', c: '34000.00', d: '12000.00', e: '9520.00', f: '26000.00', g: '23120.00', h: '2000.00', i: '1360.00',
        },
        {
          a: '合同费', b: '800000.00', c: '704000.00', d: '240000.00', e: '197120.00', f: '520000.00', g: '478720.00', h: '40000.00', i: '28160.00',
        },
        {
          a: '促销品/陈列道具', b: '50000.00', c: '35000.00', d: '15000.00', e: '9800.00', f: '32500.00', g: '23800.00', h: '2500.00', i: '1400.00',
        },
        {
          a: '办公费', b: '5000.00', c: '7153.00', d: '1500.00', e: '2002.00', f: '3250.00', g: '4864.04', h: '250.00', i: '286.12',
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  height: 0;
  width: 0;
  color: transparent;
}
.custom-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.title {
  font-size: 20px;
  text-align: center;
  color: #333333;
  font-weight: bold;
  padding: 10px 0;
}
.echarts-container {
  display: flex;
  align-items: stretch;
}
.map-container,
.bar-container {
  flex: 1;
  height: 400px;
}
</style>
